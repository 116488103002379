<i18n lang="json">
{
	"ru": {
    "btnShowPhone": "Показать телефон",
    "serviceBooking": "Служба бронирования",
    "withCall": "При звонке назовите номер объявления",
    "callBronevik": "При звонке назовите номер предложения",
    "object": "Объект"
	},
	"en": {
    "btnShowPhone": "Show phone",
    "serviceBooking": "Reservation service",
    "withCall": "When calling, call the ad number",
    "callBronevik": "When calling, call the offer number",
    "object": "Object"
	}
}
</i18n>
<template>
  <div v-show="resultPhone">
    <div
      v-show="(data && !data.isButton) || isAlways"
      class="view-phone"
      :class="{ 'view-phone__bronevik': isBronevik }"
    >
      <div v-if="!isBronevik" class="view-phone__item tmp-font--small">
        {{ t("serviceBooking") }}:
      </div>
      <template v-if="isBronevik">
        <div
          class="view-phone__item view-phone__item_number tmp-font--average_bold"
        >
          <a :href="`tel:${bronevikTel}`">{{ bronevikNumber }}</a>
        </div>
      </template>
      <template v-else>
        <div
          v-if="
            whitelabel &&
            whitelabel.phone &&
            whitelabel.phone.simple &&
            whitelabel.phone.beauty
          "
          class="view-phone__item view-phone__item_number tmp-font--average_bold"
        >
          <a :href="`tel:${whitelabel.phone.simple}`">{{
            whitelabel.phone.beauty
          }}</a>
        </div>
        <div
          v-else-if="existsPhone && existsPhone[1] && existsPhone[0]"
          class="view-phone__item view-phone__item_number tmp-font--average_bold"
        >
          <a :href="`tel:${existsPhone[0]}`">{{ existsPhone[1] }}</a>
        </div>
        <div
          v-else-if="isAlways"
          class="view-phone__item view-phone__item_number tmp-font--average_bold"
        >
          <a :href="`tel:${defaultNumber}`">{{ defaultNumber }}</a>
        </div>
      </template>
      <div v-if="isBronevik" class="view-phone__item tmp-font--small">
        {{ t("callBronevik") }}:
      </div>
      <div v-else class="view-phone__item tmp-font--small">
        {{ t("withCall") }}:
      </div>
      <div class="view-phone__item tmp-font--small">
        <div
          class="view-phone__alert tmp-font--small"
          :class="{
            'view-phone__alert_grey': canHold,
            'view-phone__alert_yellow': !canHold,
          }"
        >
          <span>№&nbsp;{{ objectId }}</span>
        </div>
      </div>
    </div>

    <div
      v-show="data && data.isButton"
      class="aside-line aside-line-text-pad text-center"
    >
      <button
        class="btn block-inline btn-object-more btn-outline-secondary btn-lg"
        @click="data.isButton = !data.isButton"
      >
        <span class="icon-app-phone"></span>
        <span>{{ t("btnShowPhone") }}</span>
      </button>
    </div>
  </div>
  <div
    v-if="!resultPhone && object.id"
    class="object-id"
  >
    {{ t("object") }} <span>№ {{ object.id }}</span>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Phone } from "@/components/api/";
import { HTTP } from "@/components/api/";
import { useI18n } from 'vue-i18n';

export default {
  name: "ViewPhone",
  components: {},
  props: {
    object: {
      /**
       * Из данного props нам нужны данные считается ли объект отелем
       * this.object.hotel
       * Если объект не отлель, то hotel не приходит
       */
      type: Object,
      required: false,
    },
    calcInfo: null,
    isAlways: false, // принудительно отображает весь компонент
    isBronevik: {
      type: Boolean,
      default: false,
    },
    whitelabel: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      // data: require("@/assets/json/settingsPhone.json") /* Импортируем JSON файл с настройками для показа компонента */,
      data: null,
      isMomentBooking: false,
      isPrice: false,
      isView: null,
      defaultNumber: "+7 (800) 555-26-08",
      bronevikNumber: "+7 (800) 444-80-41",
      bronevikTel: "+78004448041",
    };
  },
  mounted() {
    this.settingsPhone();
  },
  methods: {
    ...mapMutations("detail", ["setLocationObject"]),
    ...mapActions("detail", ["getLocationsCntObjects"]),
    settingsPhone() {
      Phone.settingsPhone()
        .then((response) => {
          const data = response.data;
          const numJSON = JSON.parse(
            data.replace(/(\/\*([\s\S]*?)\*\/)|(\/\/(.*)$)/gm, "").match(/{.*/g)
          );
          this.data = numJSON;
        })
        .catch((error) => {
          console.log("Ajax error: Phone.settingsPhone, ", error);
        });
    },
  },
  computed: {
    /**
     * Из store(vuex) нам нужно:
     *
     * Переменная id, this.location.id
     * Дата заезда и Дата отъезда
     *
     * */
    ...mapState("search", ["checkIn", "checkOut", "location"]),
    ...mapState("detail", ["locationObject", "locationsCntObjectsLoad"]),
    ...mapState("booking", ["canHold"]),
    resultPhone() {
      if (this.data) {
        if (
          this.data
            .isWorking /* Если true - отображать кнопку нужно, false - не отображать кнопку совсем. */ &&
          this
            .existsPhone /* Если значение - отображать кнопку нужно, false - не отображать кнопку совсем. */ &&
          this
            .checkWorkTime /* Если значение - отображать кнопку нужно, false - не отображать кнопку совсем. */ &&
          this
            .checkPrice /* Если значение - отображать кнопку нужно, false - не отображать кнопку совсем. */ &&
          this.checkDates
        ) {
          return true;
        } else if (this.isAlways) {
          return true;
        } else {
          return false;
        }
      } else if (this.isAlways) {
        return true;
      } else {
        return false;
      }
    },
    typePhone() {
      /*********************************************
       *
       * Проверка объекта на то, что он отель
       *
       * @function existPhone
       *
       * Возвращает {string} "hotels" либо "others"
       *
       *********************************************/

      return this.object?.hotel || this.object?.hotel == undefined
        ? "others"
        : "hotels";
    },
    existsPhone() {
      if (!this.locationObject.countryId && this.object?.id) {
        let formData = new FormData();
        formData.append(`filter[object_id]`, this.object.id);

        if (!this.locationsCntObjectsLoad) {
          this.getLocationsCntObjects(formData);
        }
      }
      /*********************************************
       *
       * Проверка на присутствие нашего объекта в одном из трёх объектов
       *
       * @function existPhone
       *
       * Возвращает {object} либо false если не существует
       *
       * Пример:
       *
       * { "+74991105191", "+7 (499) 110-51-91" }
       *
       *********************************************/
      if (this.locationObject.countryId) {
        if (this.data && this.data.locations) {
          const phones =
            this.data.locations.cities[this.locationObject.cityId] ||
            this.data.locations.regions[this.locationObject.regionId] ||
            this.data.locations.countries[this.locationObject.countryId];

          return phones ? phones[this.typePhone] : false;
        } else {
          return false;
        }
      } else {
        return false;
      }
      // if (phones)
      //   return phones[this.typePhone]
      // else
      //   return false
    },
    checkWorkTime() {
      /*********************************************
       *
       * Проверка на рабочее время
       *
       * @function checkWorkTime
       *
       * @param {string}  price          | '900'  | Общая стоимость мгновенного бронирования объекта |
       * @param {boolean} isMomentBookingNow   | 'true' | Если сейчас бронирует |
       *
       *********************************************/

      const сurrentTime = new Date().getUTCHours() + 3;

      /*********************************************
       * @param {object} time_msk     | Объект определяющий интервал рабочего дня |
       * @param {number} time_msk.min | Начало рабочего дня |
       * @param {number} time_msk.max | Конец рабочего дня |
       *********************************************/

      return this.data.time_msk.min <= сurrentTime &&
        сurrentTime < this.data.time_msk.max
        ? true
        : false;
    },
    checkPrice() {
      /*********************************************
       *
       * Проверка для того чтобы не звонили при маленькой цене бронирования
       *
       * @param {object} min_cost       | Объект определяющий минимальные стоимости бронирования объекта |
       * @param {number} min_cost.is_mb | Минимальная общая стоимость мгновенного бронирования объекта |
       * @param {number} min_cost.other | Минимальная общая стоимость запроса на бронирование объекта |
       *
       * @param {boolean} isPriceNeed
       * true - действует настройка по минимальной цене проживания min_cost
       * false - min_cost не действует.
       *
       *********************************************/

      if (!this.data.isDatesNeed) {
        return true;
      } else {
        let price = this.calcInfo?.price
          ? this.calcInfo?.price
          : this.calcInfo?.data?.price
          ? this.calcInfo?.data?.price
          : null;

        if (price) {
          return this.data.isPriceNeed
            ? this.bookingNow
              ? this.data.min_cost.is_mb <= price
                ? true
                : false
              : this.data.min_cost.other <= price
              ? true
              : false
            : false;
        } else {
          return false;
        }
      }
    },
    checkDates() {
      if (!this.data.isDatesNeed) {
        return true;
      } else {
        return this.data.isDatesNeed && this.data.isPriceNeed
          ? this.checkIn && this.checkOut
            ? true
            : false
          : false;
      }
    },
    checkButton() {
      return this.data.isButton;
    },
    bookingNow() {
      return this.calcInfo.is_booking_now;
    },
    objectId() {
      if (!this.object.id) return false;
      let newNum;
      if (this.isBronevik) {
        return `Б-${this.object.id}`;
      } else {
        if (String(this.object.id).length > 4) {
          newNum = String(this.object.id).replace(
            /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
            "$1 "
          );
        } else {
          newNum = String(this.object.id);
        }
        return newNum;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-phone {
  margin-top: 20px;
  color: #000;

  &__bronevik {
    font-size: 16px;

    .view-phone__item {
      font-size: 16px;

      &_number {
        font-size: 18px;
      }
    }
    .view-phone__alert {
      font-size: 16px;
    }
  }

  &__item {
    display: flex;
    justify-content: center;

    padding-top: 5px;

    color: #7f7f7f;

    &:first-child {
      padding-top: 0;
    }

    &_number {
      font-size: 16px;
      padding-bottom: 5px;
      a,
      a:hover,
      a:focus,
      a:active {
        color: #000;
      }
    }
  }
  &__alert {
    color: #000;
    padding: 8px 9px;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 13px;
    margin-top: 2px;

    &_yellow {
      background: #fff6c4;
      border: 1px solid #f1c260;
    }
    &_white {
      background: #fff;
    }
    &_grey {
      border: 1px solid #d8d8d8;
      background: #d8d8d8;
    }
  }
}

.object-id {
  margin-top: 20px;
  text-align: center;
  span {
    font-weight: 500;
  }
}
</style>
